import React, { useState } from "react";
// import { allReportsData } from "../../data";
import { useStepsContext } from "../../Context/StateContext";
import {
  useGetAllPendingReports,
  useGetAllReviewedReports,
  useGetAllUnderReviewReports,
} from "../../Hooks/reports-hooks";
import PriorityColor from "./PriorityColor";
import { handleDateFormat } from "../../utils/date";

const AllReports = () => {
  // const { setStep, rows } = useStepsContext();

  const { data: pendingReportsData, isLoading: pendingReportLoading } =
    useGetAllPendingReports();

  return (
    <div className="w-[90%] mx-auto my-10">
      {/* Top Container */}
      <div
        className={`flex justify-between items-start mb-10 ${
          pendingReportsData?.results?.length === 0 &&
          "border-b border-lightgrey"
        }`}
      >
        {/* Left */}
        <div>
          <h1 className="text-darkBlack font-bold text-[40px] leading-[64px]">
            Companies
          </h1>
          <p className="text-reportGrey text-lg font-karla font-normal leading-[36px]">
            Overview all of companies here
          </p>
        </div>
      </div>

      {/* Reports Container */}
      <div className="w-full gap-[24px] grid grid-cols-3 ">
        <Report
          data={pendingReportsData}
          activeTab={1}
          loading={pendingReportLoading}
        />
      </div>
    </div>
  );
};

export default AllReports;

const Report = ({ data, activeTab, loading }) => {
  const { setStep, setCompany, setSpecificReportDetailsID } = useStepsContext();

  const handleNavigate = async (report, id) => {
    setCompany(report);
    setSpecificReportDetailsID(id);
    // console.log("report: ", report);

    if (activeTab === 1) {
      setStep("specific_report");
    }
  };

  if (data?.results.length === 0) {
    return <p>No companies found in demo.</p>;
  }
  return (
    <>
      {data?.results.length > 0
        ? data?.results.map((report, index) => (
            <div
              key={index}
              // onClick={() => setStep("specific_report")}
              onClick={() => {
                handleNavigate(report?.companyName, report.id);
              }}
              style={{
                boxShadow:
                  " 0px 13px 12px -16px rgba(0, 0, 0, 0.05), 0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
              }}
              className="p-4 cursor-pointer rounded-xl border border-borderLight hover:border-black"
            >
              <p className="text-[#6C7275] text-[14px] mb-[4px] font-medium">
                {loading
                  ? "loading..."
                  : report?.createdAt && handleDateFormat(report?.createdAt)}
              </p>
              <h1 className="mb-3 text-darkBlack text-2xl font-semibold">
                {loading ? "Loading..." : report?.companyName}
              </h1>
              <p className="text-[#6C7275] mt-[16px] text-[14px] mr-3 font-medium">
                Jurisdiction :
                <span className="text-darkBlack font-semibold ml-2 text-sm ">
                  {loading
                    ? "loading..."
                    : report?.jurisdiction && report?.jurisdiction}
                </span>
              </p>
            </div>
          ))
        : data?.message && <p>{data?.message || "There is no Company"}</p>}
    </>
  );
};
